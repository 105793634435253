import React from "react";
import styled from "styled-components";

import { StaticData } from "./Messages";
import { Colors, device } from "./Variables";
import Icons from "./Icons";

const TextSections = styled.h1`
    color: ${Colors.ColorA};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    margin: 0.5rem 0 0.5rem 0;
`;
const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
`;

const EmailLink = styled.a`
    color: ${Colors.ColorA};
    text-decoration: none;
    font-size: 0.5rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    &:hover {
        color: ${Colors.ColorC};
        h1 {
            color: ${Colors.ColorC};
        }

        i {
            color: ${Colors.ColorC};
        }
    }
`;
const ContactWrapper = styled.div`
    display: Flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    @media ${device.mobileS} {
        justify-content: center;
        text-align: center;
        flex-direction: row;
        margin: 0 20px;
    }
    @media ${device.mobileM} {
        justify-content: center;
        text-align: center;
        flex-direction: row;
        margin: 0 20px;
    }

    @media ${device.mobileL} {
        justify-content: center;
        text-align: center;
        flex-direction: row;
        margin: 0;
    }

    @media ${device.tablet} {
        justify-content: center;
        flex-direction: row;
        margin: 0;
    }

    @media ${device.laptop} {
        justify-content: start;
        text-align: start;
        flex-direction: row;
        margin: 0;
    }
`;

const DataContactFull = () => {
    const emailvalue = `mailto:${StaticData.Email}`;
    return (
        <>
            <ContactWrapper>
                <IconWrapper>
                    <Icons name="address" />
                </IconWrapper>
                <div>
                    <TextSections>{StaticData.Address1}</TextSections>
                </div>
            </ContactWrapper>

            <ContactWrapper>
                <IconWrapper>
                    <Icons name="whatsapp" />
                </IconWrapper>
                <TextSections>{StaticData.Phone1Number}</TextSections>
            </ContactWrapper>

            {/* <ContactWrapper>
                <IconWrapper>
                    <Icons name="whatsapp" />
                </IconWrapper>
                <TextSections>{StaticData.Phone2}</TextSections>
            </ContactWrapper> */}

            <EmailLink href={emailvalue} title="Realizar Consulta" target="_blank" rel="noreferrer">
                <ContactWrapper>
                    <IconWrapper>
                        <Icons name="email" />
                    </IconWrapper>
                    <TextSections>{StaticData.Email}</TextSections>
                </ContactWrapper>
            </EmailLink>
        </>
    );
};

export default DataContactFull;
