import React from "react";

import { WappBanner, NavigationLinks } from "../components/Styles";
import { StaticData } from "./Messages";

// {StaticData.Phone1}
const BannerWapp = () => {
    return (
        <div>
            <NavigationLinks
                href={`https://api.whatsapp.com/send?phone=${StaticData.Phone1Number}&text=Hola!%20Quisiera%20hacer%20una%20consulta%20con%20la%20Dra.%20El%C3%ADas%20`}
                title="Turnos por Whatsapp"
                target="_blank"
                rel="noreferrer"
            >
                <WappBanner>
                    <div>
                        <p>CLICK AQUÍ PARA TURNOS POR WHATSAPP</p>
                    </div>
                </WappBanner>
            </NavigationLinks>
        </div>
    );
};

export default BannerWapp;
