import MainLogoTransp from "../assets/img/misc/MainLogoTransp.png";

import Background from "../assets/img/misc/Background.webp";
import BiographyImg from "../assets/img/misc/BiographyImg.webp";

// --------------IMG BRANDS--------------
import Brand1 from "../assets/img/membershiplogos/Brand1.webp";
import Brand2 from "../assets/img/membershiplogos/Brand2.webp";
import Brand3 from "../assets/img/membershiplogos/Brand3.webp";
import Brand4 from "../assets/img/membershiplogos/Brand4.webp";

// --------------IMG PRODUCTOS--------------
import Product1 from "../assets/img/products/Prod1.webp";
import Product2 from "../assets/img/products/Prod2.webp";
import Product3 from "../assets/img/products/Prod3.webp";
import Product4 from "../assets/img/products/Prod4.webp";
import Product5 from "../assets/img/products/Prod5.webp";
import Product6 from "../assets/img/products/Prod6.webp";
import Product7 from "../assets/img/products/Prod7.webp";
import Product8 from "../assets/img/products/Prod8.webp";
import Product9 from "../assets/img/products/Prod9.webp";

// --------------ICONS TREATMENTS--------------
import Treat1 from "../assets/img/treatmenticons/Treat1.svg";
import Treat2 from "../assets/img/treatmenticons/Treat2.svg";
import Treat3 from "../assets/img/treatmenticons/Treat3.svg";
import Treat4 from "../assets/img/treatmenticons/Treat4.svg";
import Treat6 from "../assets/img/treatmenticons/Treat6.svg";
import Treat7 from "../assets/img/treatmenticons/Treat7.svg";

// --------------IMG MED CENTER--------------
import MedCen1 from "../assets/img/medcenter/MedCenter1.webp";
import MedCen2 from "../assets/img/medcenter/MedCenter2.webp";
import MedCen3 from "../assets/img/medcenter/MedCenter3.webp";
import MedCen4 from "../assets/img/medcenter/MedCenter4.webp";
import MedCen5 from "../assets/img/medcenter/MedCenter5.webp";
import MedCen6 from "../assets/img/medcenter/MedCenter6.webp";
import MedCen7 from "../assets/img/medcenter/MedCenter7.webp";
import MedCen8 from "../assets/img/medcenter/MedCenter8.webp";

// -------------------DATA-------------------
export const StaticData = {
    Name: "Dra. Manuelita Elias",
    Title: "Dermatología Estética y Medicina Funcional",
    Title3: "Desde 1998",
    MN: "MN. 122085",
    MP: "MP. 81221",
    Phone1: "(+549) 115767-1928",
    Phone1Number: "5491157671928",
    //   Phone2: "(+54911) 3008-0722",
    Email: "manuelita.elias@gmail.com",
    Time: "Lunes a Viernes 14.00 - 20.00",
    Address1: "Av. del Libertador 7766 - C.A.B.A",
};

export const ImgsExport = {
    ImgLogo: MainLogoTransp,
    ImgBio: BiographyImg,
    ImgBack: Background,
};
// -------------------IMGS-------------------
export const Imgs = [
    {
        ImgBrandLogo: Brand1,
    },
    {
        ImgBrandLogo: Brand2,
    },
    {
        ImgBrandLogo: Brand3,
    },
    {
        ImgBrandLogo: Brand4,
    },
];
// -------------------BIOGRAPHY-------------------
export const BioData = [
    {
        BioParagraphTitle: "Dra. Manuelita Elias",
        BioParagraphSubTitle: "Médica Dermatóloga Y Nutricionista",
        BioParagraph: "Graduada en la Universidad Nacional de La Plata (UNLP).",
    },
    { ImgProfile: BiographyImg },
    {
        BioParagraph:
            "Realizó la concurrencia de la especialidad en Dermatología en el Hospital Houssay de Vicente López; asociado a la universidad de Buenos Aires,(UBA).",
    },
    {
        BioParagraph:
            "Continuó el posgrado anual en Dermatología Estética junto a la Dra. Graciela Ferraro, certificado por la UBA.",
    },
    {
        BioParagraph:
            "Realizó posgrado anual sobre Dermatología quirúrgica en la Asociación Argentina de Dermatología. Obtiene, la especialidad de Nutrición, avalado por la Sociedad Argentina de Nutrición (SAN).",
    },

    {
        CurrentActivityTitle: "ACTUALMENTE DESARROLLA SU ACTIVIDAD COMO:",
    },
    {
        CurrentActivityBullet: "> Miembro activo de la SAD (Sociedad Argentina de Dermatología).",
    },
    {
        CurrentActivityBullet: "> Asesora en tratamientos de estética capilar (SAD).",
    },

    {
        CurrentActivityBullet:
            "> Asesora de trabajos complementarios con ac hialurónico. SAD . Soc. arg de dermatologí.",
    },
    {
        CurrentActivityBullet: "> Asesora de PRP , plasma rico en plaqueta (SAD).",
    },
    {
        CurrentActivityBullet: "> Asesora tratamientos complementarios en peeling (SAD).",
    },
    {
        CurrentActivityBullet:
            "> Medica Dermatologa de Staff del centro de dermatologia Estetica del dr.Segio Escobar(CABA).",
    },
    {
        CurrentActivityBullet:
            "> Actualmente, titular Dermatologa-Estética de centro de estética y dermatologia , C&M Estetica (CABA).",
    },
];

// -------------------FILOSOFIA-------------------
export const PhilosophyData = [
    {
        PhilosophyTitle:
            "Nuestra filosofia se basa en lograr el equilibrio entre la belleza, la salud y la calidad de vida",
    },
    {
        PhilosophyParagraph:
            "La búsqueda de vivir en armonía con la propia imagen es uno de los principales factores que llevan a una persona a la realización de un procedimiento estético.",
    },
    {
        PhilosophyParagraph:
            "Tomar la decisión de efectuarse una cirugía plástica o procedimiento estético es un paso trascendental en cada individuo y nunca debe pensarse ni resolverse precipitadamente; ya que dicha decisión repercute, entre otras cosas, en la estabilidad emocional de la persona buscando mejorar así la calidad de vida y su relación con el mundo que lo rodea.",
    },
    {
        PhilosophyParagraph:
            "La evaluación que se realiza a través de la consulta médica, determinará si el procedimiento o la cirugía es necesaria o no y en caso afirmativo, se elegirá el más adecuado. Lo primordial es saber que cada caso debe ser analizado cuidadosamente, teniendo en cuenta los deseos del paciente y sus verdaderas expectativas.",
    },
    {
        PhilosophyParagraph:
            "La atención personalizada de cada paciente es nuestro trabajo como así también nuestra filosofía. Brindar todas las herramientas necesarias para que el paciente pueda sentirse contenido e informado en todas las etapas de los procedimientos; siendo para nosotros nuestro primordial compromiso.",
    },
    {
        PhilosophyParagraph:
            "El éxito del trabajo se muestra como el resultado de una excelente relación médico-paciente basada en expectativas realistas, el saber y la experiencia.",
    },
];

// -------------------TREATMENTS-------------------

export const TreatmentsData = [
    {
        Id: 1,
        TreatmentsTitle: "Tratamientos",
        TreatmentsText:
            "Nuestra filosofía se basa en lograr el equilibrio entre la belleza, la salud y la calidad de vida. La búsqueda de vivir en armonía con la propia imagen es uno de los principales factores que llevan a una persona a la realización de un procedimiento estético.",
        TreatmentIcon: Treat1,
        TreatmentName: "Toxina Botulinica",
        TretmentParagraph: [
            {
                Paragraph:
                    "La toxina botulínica actúa sobre los músculos de la cara relajándolos y disminuyendo el impacto de las contracciones en la piel.",
            },
            {
                Paragraph:
                    "Se inyecta superficialmente en las zonas donde hay arrugas faciales de expresión como en el tercio superior del rostro para eliminar las “patas de gallo”, el entrecejo, frente.",
            },
            {
                Paragraph:
                    "También puede ser utilizada en patologias como hiperhidrosis para disminuir el exceso de transpiracion.",
            },
            {
                Paragraph:
                    "Se aplica mediante microinyecciones prácticamente indoloras. Se logra en un tiempo muy corto, unos 15 minutos.",
            },
            {
                Paragraph: (
                    <>
                        {" "}
                        <>El efecto de la toxina botulínica comienza aproximadamente a los 15 dias de ser aplicada y </>
                        <b>tiene una duracion de 3 a 6 meses.</b>
                    </>
                ),
            },
        ],
    },

    {
        Id: 2,
        TreatmentName: "Rellenos con Acido Hialuronico",
        TreatmentIcon: Treat1,
        TretmentParagraph: [
            {
                Paragraph:
                    "Es una sustancia que se halla presente en el cuerpo humano de forma natural y contribuye a hidratar y a dar volumen a la piel.",
            },
            {
                Paragraph:
                    "Se usa como relleno facial, para aumento de labios, zona peribucal, pómulos, tratamientos para eliminar las ojeras.",
            },
            {
                Paragraph:
                    "El ácido hialurónico se deposita en las arrugas mediante pequeñas infiltraciones que inmediatamente rellenan los pliegues de la piel.",
            },
            {
                Paragraph: (
                    <>
                        <>
                            Una vez finalizado el tratamiento se puede volver a retomar la actividad normal. El efecto
                            del ácido hialurónico <b>puede durar de 9 a 12 meses</b> en función de la edad, de la zona
                            inyectada, de la calidad de la piel y del estilo de vida.
                        </>
                    </>
                ),
            },
            {
                Paragraph:
                    "Al tratarse de un producto biodegradable, y que el organismo consume de manera natural, tiene la ventaja de ser eliminado paulatinamente.",
            },
            {
                Paragraph:
                    "Lo que permite retoques de acuerdo a la necesidad del paciente, al contrario de un procedimiento quirúrgico.",
            },
        ],
    },

    {
        Id: 3,
        TreatmentName: "Plasma rico en plaquetas",
        TreatmentIcon: Treat4,
        TretmentParagraph: [
            {
                Paragraph:
                    "Es un tratamiento estético que promueve la regeneración celular para conseguir una piel más luminosa y tersa, con mejor textura, menos flaccidez y menos arrugas.",
            },
            {
                Paragraph:
                    "Se utiliza las propias proteínas de nuestro organismo, seleccionadas y concentradas, para que reparen el daño producido por el paso del tiempo o por las lesiones solares.",
            },
            {
                Paragraph:
                    "En este tratamiento el ingrediente principal es la sangre del propio paciente. Es un tratamiento ambulatorio que se realiza en la consulta.",
            },
            {
                Paragraph:
                    "Se realiza una extracción de sangre del propio paciente, de la que se obtendrá el plasma rico en plaquetas, tras someterla a una centrífuga y activarla.",
            },
            {
                Paragraph:
                    "Una vez preparada se aplicará a través de multipunciones con una aguja muy fina, en forma de mesoterapia.",
            },
            {
                Paragraph: <b>El número de sesiones dependerá del estado de la piel de cada paciente.</b>,
            },
        ],
    },

    {
        Id: 4,
        TreatmentIcon: Treat2,
        TreatmentName: "Peelings",
        TretmentParagraph: [
            {
                Paragraph:
                    "Es un tratamiento que hace una exfoliación selectiva de la piel a mayor o menor profundidad.",
            },
            {
                Paragraph:
                    "Existen diversos tipos, cada uno enfocado en un tratamiento y para lograr un resultado diferente.",
            },
            {
                Paragraph:
                    "Se puede realizar en cualquier parte del cuerpo y para diversas afecciones cutáneas como marcas de acné, fotoenvejecimiento, manchas, cicatrices, hiperqueratosis, asperezas, etc",
            },
        ],
    },

    {
        Id: 5,
        TreatmentIcon: Treat2,
        TreatmentName: "Dermapen",
        TretmentParagraph: [
            {
                Paragraph: "Es un tratamiento para fomentar la producción de colágeno propio del paciente.",
            },
            {
                Paragraph:
                    "Es eficaz en la reducción de las líneas de expresión, las arrugas, minimiza las estrías, las cicatrices, las marcas por acné y estimula el crecimiento del cabello.",
            },
            {
                Paragraph:
                    "El Dermapen es un dispositivo compuesto por múltiples micro-agujas y sirve para reafirmar, levantar y rejuvenecer la piel.",
            },
            {
                Paragraph: <b>Cada sesión tiene una duración de entre 30-60 minutos.</b>,
            },
            {
                Paragraph: "Se realiza en la propia consulta y de forma ambulatoria.",
            },
            {
                Paragraph:
                    "Se generará un leve enrojecimiento durante 12-24 horas y luego se producirá una mejora paulatina de la piel por incremento del nuevo colágeno.",
            },
        ],
    },

    {
        Id: 6,
        TreatmentIcon: Treat6,
        TreatmentName: "Fosfatidilcolina",
        TretmentParagraph: [
            {
                Paragraph:
                    "El tratamiento consiste en una serie de sesiones durante las que se aplican inyecciones de fosfatidilcolina, un extracto derivado de la lecitina de soja que forma parte estructural de membranas celulares de nuestro cuerpo y no produce reacciones alérgicas, ya que el organismo no la reconoce como extraña. ",
            },
            {
                Paragraph:
                    "Las indicaciones para el uso estético de la fosfatidilcolina son limitadas y se restringen a pequeños depósitos de adiposidad localizada, es conveniente complementar el tratamiento con dieta y actividad fisica.",
            },
            {
                Paragraph:
                    "La técnica de aplicación es la infiltración subcutánea mediante la cual la sustancia es inyectada directamente en la grasa.",
            },
            {
                Paragraph:
                    "Al penetrar en el tejido adiposo, la fosfatidilcolina interactúa en la membrana celular, produciendo orificios en ella con la consecuente destrucción del tejido graso sólido tratado, que una vez disuelto se vuelve más soluble.",
            },
            {
                Paragraph:
                    "Esto permite que luego, el mismo organismo la absorba y la elimine en forma natural en pequeñas cantidades por las deposiciones y especialmente a través de la orina.",
            },
            {
                Paragraph: <b>Se realizan 6 sesiones cada 2 semana o un mes.</b>,
            },
        ],
    },

    {
        Id: 7,
        TreatmentIcon: Treat1,
        TreatmentName: "Radiesse",
        TretmentParagraph: [
            {
                Paragraph:
                    "Es una de las soluciones más efectivas y novedosas para devolver volumen y eliminar la flacidez de la cara son los rellenos faciales de hidroxiapatita de calcio.",
            },
            {
                Paragraph:
                    "Estos rellenos faciales de nueva generación se utilizan para reducir las arrugas y pliegues del rostro.",
            },
            {
                Paragraph:
                    "Se puede aplicar en líneas de expresión como pueden ser las patas de gallo, arrugas y pliegues en mejillas, mentón y barbilla.",
            },
            {
                Paragraph:
                    "Arrugas mandibulares y en el óvalo facial, así como también en las manos. Los rellenos Radiesse se inyectan con una una aguja fina en la piel, sin sentir ningún tipo de molestia.",
            },
            {
                Paragraph:
                    "Por lo general con una única sesión donde se inyecte un vial de Radiesse es suficiente para tratar la zona deseada, aunque en ocasiones puede ser necesario dar algún repaso para lograr los resultados finales deseados.",
            },
            {
                Paragraph:
                    " Al no tener componentes de origen animal, Radiesse es uno de los rellenos más seguros y adecuados para tratar la flacidez facial sin apenas riesgos ni efectos secundarios.",
            },
            {
                Paragraph: "",
            },
        ],
    },

    {
        Id: 8,
        TreatmentIcon: Treat7,
        TreatmentName: "Hilos Tensores",
        TretmentParagraph: [
            {
                Paragraph:
                    "Estimulan la producción de colágeno para tratar la flacidez corporal desde dentro, así como rejuvenecer la piel de muslos, brazos y glúteos sin pasar por quirófano.",
            },
            {
                Paragraph: (
                    <b>
                        <i>Su aplicación es rápida, la sesión no suele durar más de 60 minutos.</i>
                    </b>
                ),
            },
            {
                Paragraph:
                    "Estos productos son invisibles e imperceptibles al tacto. Para aplicarlos solo basta una crema anestésica y no producen alergias ni rechazo de ningún tipo.",
            },
            {
                Paragraph:
                    "Una vez implantados, estos hilos tensores producen un doble efecto lifting. Por un lado, cada hilo genera una fibrosis tensora en los tejidos circundantes.",
            },
            {
                Paragraph:
                    "Este proceso regenera los tejidos aledaños. Por otro, se induce la génesis de colágeno y elastina, proteínas que mantienen unidos los tejidos y que, por tanto, son responsables de la tersura y firmeza de la piel.",
            },
        ],
    },

    {
        Id: 9,
        TreatmentIcon: Treat1,
        TreatmentName: "Sculptra",
        TretmentParagraph: [
            {
                Paragraph:
                    "Revierte el envejecimiento facial de forma progresiva, gracias a su acción estimuladora de colágeno.",
            },
            {
                Paragraph:
                    "Restaura la pérdida del volumen facial devolviendo a la piel una apariencia tersa y juvenil.",
            },
            {
                Paragraph:
                    "El efecto es de larga duración, hasta dos años. El resultado se obtiene de forma progresiva, tras dos o tres sesiones, comenzándose a ver los resultados en unas seis semanas.",
            },
        ],
    },

    {
        Id: 10,
        TreatmentIcon: Treat1,
        TreatmentName: "Profhilo",
        TretmentParagraph: [
            {
                Paragraph:
                    "Es ácido hialurónico inteligente que actúa como tratamiento de biorremodelación con efecto tensor inmediato.",
            },
            {
                Paragraph:
                    "Tiene una acción biorregeneradora que estimula la producción de nuevas células y un efecto tensor que hace contrarrestar la distensión de la piel mientras remodela la cara.",
            },
            {
                Paragraph:
                    "Contiene una de las concentraciones más altas de ácido hialurónico y ofrece una eficacia prolongada en el resultado sin la adición de agentes químicos reticulantes.",
            },
            {
                Paragraph:
                    "Una vez es inyectado, Profhilo trabaja en la regeneración de la piel desde lo más profundo.",
            },
            {
                Paragraph:
                    "Funciona como un tratamiento preventivo en personas jóvenes y como un tratamiento anti-envejecimiento para las personas de edad más avanzada.",
            },
        ],
    },

    {
        Id: 11,
        TreatmentIcon: Treat3,
        TreatmentName: "Aparatología - Laser CO2",
        TretmentParagraph: [
            {
                Paragraph:
                    "El Láser de CO2 se utiliza para realizar el resurfacing facial para mejorar arrugas, poros dilatados, hiperpigmentaciones (manchas) en cara y manos, cicatrices de acné o de otros orígenes. ",
            },
            {
                Paragraph:
                    "Esta técnica que se ha convertido en el procedimiento número uno para los pacientes que requieren rejuvenecimiento facial, del cuello y de las manos, con excelentes resultados en corto plazo.",
            },
            {
                Paragraph:
                    "Durante la aplicación, la energía pulsada que emite este láser calienta el H2O hasta llegar a su punto de ebullición, generando así la vaporización de las capas superficiales de piel. Debido a esto comienza el proceso de regeneración natural del cuerpo que da lugar a una piel nueva y sin lesiones. ",
            },
            {
                Paragraph: (
                    <u>
                        <b>El láser actúa a dos niveles:</b>
                    </u>
                ),
            },
            {
                Paragraph: (
                    <ol>
                        <li>
                            <u>
                                <b>Nivel superficial: </b>
                            </u>
                            <div>
                                Mejora las arrugas, las manchas y estrías debido a que elimina una fina capa de piel.
                            </div>
                        </li>

                        <li style={{ marginTop: "20px" }}>
                            <u>
                                <b>Nivel profundo:</b>
                            </u>
                            <div>
                                En forma inmediata produce una retracción del colágeno logrando efectos de “lifting” o
                                levantamiento, aumentando la firmeza de la piel y a largo plazo provoca un efecto
                                estimulante de colágeno obteniéndose un efecto de rejuvenecimiento cutáneo.
                            </div>
                        </li>
                    </ol>
                ),
            },

            {
                Paragraph: (
                    <>
                        <>
                            Por lo general <b>se requieren de 3 sesiones</b>{" "}
                            <i>
                                <u>(según el caso)</u>
                            </i>{" "}
                            para conseguir resultados.
                        </>
                    </>
                ),
            },
            {
                Paragraph:
                    "La duración del procedimiento es de unos pocos minutos. Aunque a mayor superficie a tratar, más tiempo de aplicación.",
            },
        ],
    },

    {
        Id: 12,
        TreatmentIcon: Treat3,
        TreatmentName: "Aparatología - IPL",
        TretmentParagraph: [
            {
                Paragraph:
                    "La luz intensa pulsada o IPL elimina las huellas que los años, el estrés, y sobretodo, el sol, dejan en la piel, proporcionando un rejuvenecimiento global y uniforme del rostro, cuello, escote e incluso manos.",
            },
            {
                Paragraph:
                    "La IPL emite una banda ancha de luz que permite tratar varios objetivos a la vez, manchas pigmentadas, venitas o textura cutánea rugosa y así mejorar globalmente la calidad de la piel al estimular la formación de colágeno.",
            },
            {
                Paragraph:
                    "La energía de la luz actúa en las capas más profundas para mejorar gradualmente la tonalidad y textura de la piel, eliminar las manchas, rojeces, poros dilatados, arrugas finas y dar luminosidad.",
            },
            {
                Paragraph: "El tratamiento se inicia con la piel perfectamente limpia, sin maquillaje ni cremas.",
            },
            {
                Paragraph:
                    "Se realiza utilizando un gel frío sobre la zona que permite la transmisión de la energía de la luz y un sistema de enfriamiento que protege las capas más superficiales de la piel.",
            },
            {
                Paragraph: (
                    <>
                        <>
                            En función del tamaño de la zona a tratar el procedimiento{" "}
                            <b>puede durar desde 30 minutos hasta una hora</b>.
                        </>
                    </>
                ),
            },

            { Paragraph: "Los resultados son evidentes desde la primera sesión." },
            { Paragraph: <b>Se necesitan entre 3 y 5 sesiones.</b> },
        ],
    },

    // {
    //     Id: 13,
    //     TreatmentIcon: Treat3,
    //     TreatmentName: "Aparatología - HIFU",
    //     TretmentParagraph: [
    //         {
    //             Paragraph:
    //                 "El tratamiento HIFU consiste en la aplicación de un haz de ultrasonido de alta frecuencia y alta energía en la piel, para remodelar su tejido, mediante ablación térmica.",
    //         },
    //         {
    //             Paragraph:
    //                 "Se emplea fundamentalmente para reducir arrugas, líneas de expresión, adiposidad localizada y tensar la piel.",
    //         },
    //         {
    //             Paragraph:
    //                 "Los aparatos de HIFU en estética emiten energía ultrasónica de forma concentrada en un haz de sonido, del mismo modo que una lupa con los rayos del sol, sin causar daño alguno a los tejidos.",
    //         },
    //         {
    //             Paragraph:
    //                 "Estos aumentan la temperatura de la zona tratada a unos 65° / 70°, localizando el calor a unos 4.5 mm de la epidermis, lo que consigue miles de puntos de coagulación muy precisos a diferentes profundidades.Dicho mecanismo permite hacer la neo-colagenogénesis (remodelación del colágeno), un proceso en el cual los tejidos comienzan a repararse, mejorando notablemente la apariencia de la piel desde la primera sesión.",
    //         },
    //         {
    //             Paragraph: (
    //                 <>
    //                     <>
    //                         La aplicación de la terapia puede soportarse con facilidad y{" "}
    //                         <b>suele durar de 30 minutos a 2 horas</b>.
    //                     </>
    //                 </>
    //             ),
    //         },
    //         {
    //             Paragraph:
    //                 "Habitualmente, se sienten molestias cuando la energía es liberada, pero esto dura solo unos instantes.",
    //         },
    //         {
    //             Paragraph: <b>Los resultados finales se observan pasados 1 ó 2 meses.</b>,
    //         },
    //     ],
    // },
    {
        Id: 14,
        TreatmentIcon: Treat1,
        TreatmentName: "Long Lasting",
        TretmentParagraph: [
            {
                Paragraph:
                    "Long lasting es un bioredensificador con propiedades de hidratación dérmica de larga duración, compuesto por una tecnología de reticulación (UHI) reciente y patentada destinada a garantizar una alta eficacia y singularidad del producto. Formulado por AH reticulado y de alto peso molecular, acompañado por un buffer protector.",
            },
            {
                Paragraph: "Se pueden tratar el rostro, cuello, escote y las manos en una misma sesión.",
            },
            {
                Paragraph: "El resultado obtenido perdura entre 4 a 6 meses.",
            },
            {
                Paragraph: "Tratamiento de rejuvenecimiento, redensificación y efecto tensor en la piel.",
            },
        ],
    },
];

export const MedCenter = [
    {
        MedCenterTitle: "El centro",
    },
    {
        MedCenterParagraph:
            "Nuestro objetivo principal es brindar los procedimientos más eficaces y seguros en Cirugía Plástica y Medicina Estética; respetando y favoreciendo la armonía y el bienestar de cada persona.",
    },
    {
        MedCenterParagraph:
            "Contamos con un equipo de profesionales médicos altamente capacitados en diferentes áreas de la salud, tales como Cirugía Plástica, Dermatología, Flebología Estética, Clínica Médica y Medicina Estética.",
    },
    {
        MedCenterParagraph:
            "Brindamos las alternativas más confiables; utilizando los mejores métodos diagnósticos, empleando tecnología de última generación y los procedimientos innovadores disponibles para tal fin.",
    },
    {
        MedCenterParagraph:
            "El centro dispone de instalaciones diseñadas especialmente para la comodidad y el bienestar de cada uno de nuestros pacientes. Se encuentra ubicado en el barrio de Palermo una zona céntrica y accesible de la ciudad de Buenos Aires.",
    },
];

export const ProductsArray = [
    {
        ImgProduct: Product1,
        ProductName: "Crema anti-age día",
        ProductDescription: "Contiene Niacinamida 1%, ácido Mándelico 6%, ácido Hialuronico 1% y  Urea %6.",
    },
    {
        ImgProduct: Product3,
        ProductName: "Crema anti-age noche",
        ProductDescription: "Contiene ácido Mándelico 6%, ácido Azelaico 10%, ácido Hialuronico 2% y Urea 6%",
    },
    {
        ImgProduct: Product2,
        ProductName: "Crema gel acne",
        ProductDescription: "Contiene ácido Mándelico 4%, ácido Glicolico 4% y Urea 4%",
    },

    {
        ImgProduct: Product4,
        ProductName: "Contorno de ojos",
        ProductDescription: "Contiene Argireline, Retinol y vitamina E.",
    },
    {
        ImgProduct: Product5,
        ProductName: "Tónico descongestivo",
        ProductDescription: "Tónico de Hamamelis",
    },
    {
        ImgProduct: Product6,
        ProductName: "Loción de limpieza",
        ProductDescription: "Gel de limpieza hipoalergénico",
    },
    {
        ImgProduct: Product7,
        ProductName: "Serum",
        ProductDescription: "Vitamina C estabilizada & ácido Hialuronico concentrado",
    },
    {
        ImgProduct: Product8,
        ProductName: "Máscara descongestiva",
        ProductDescription: "Hamamelis, Calendula & Manzanilla",
    },
    {
        ImgProduct: Product9,
        ProductName: "Labial",
        ProductDescription: "Vitamina E pura",
    },
];

export const ImgMedCenter = [
    {
        ImgCenter: MedCen1,
        Title: "a",
        Id: 1,
    },
    {
        ImgCenter: MedCen2,
        Title: "b",
        Id: 2,
    },
    {
        ImgCenter: MedCen3,
        Title: "c",
        Id: 3,
    },
    {
        ImgCenter: MedCen4,
        Title: "d",
        Id: 4,
    },
    {
        ImgCenter: MedCen5,
        Title: "f",
        Id: 5,
    },
    {
        ImgCenter: MedCen6,
        Title: "g",
        Id: 6,
    },
    {
        ImgCenter: MedCen7,
        Title: "h",
        Id: 7,
    },
    {
        ImgCenter: MedCen8,
        Title: "i",
        Id: 8,
    },
];
